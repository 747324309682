import React from 'react';
import {GetStaticProps} from 'next';
import {
  AuthStore,
  CheckoutStore,
  getStaticPropsForAppWrapper,
} from 'ui/components/withAppWrapper';
import DefaultErrorPage from 'next/error';
import {DatoPageContent} from 'ui/components/runway-east/page/DatoPageContent';
import {fetchPageQuery, PageQuery} from 'hooks/use-page-query';
import {JsonLd} from 'ui/components/runway-east/json-ld/JsonLd';
import {HOST} from 'hooks/use-environment';
import {inject} from 'mobx-react';

interface IndexPageProps {
  pageQuery: PageQuery;
  authStore: AuthStore;
  checkoutStore: CheckoutStore;
}

const Index = ({pageQuery, ...mobxStoreProps}: IndexPageProps) => {
  if (!pageQuery.data) return <DefaultErrorPage statusCode={404} />;

  return (
    <div>
      <JsonLd
        schema={{
          '@type': 'Organization',
          url: HOST,
          name: 'Runway East',
        }}
      />
      <DatoPageContent {...mobxStoreProps} pageQuery={pageQuery} />
    </div>
  );
};

export const getStaticProps: GetStaticProps = getStaticPropsForAppWrapper(
  undefined,
  async (_context, apolloClient) => {
    const pageQuery = await fetchPageQuery(apolloClient, {
      path: '/',
    });

    return {
      props: {
        pageQuery,
      },
    };
  },
);

export default inject('authStore', 'checkoutStore')(Index);
